@import url("https://fonts.googleapis.com/css2?family=Baumans&family=MuseoModerno:wght@100;200;300;400;500;600;700;800;900&family=Righteous&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.content {
    /* font-family: 'Baumans', cursive; */
    /* font-family: 'MuseoModerno', cursive; */
    font-family: 'Righteous', cursive;
	position: relative;
}

.content h2 {
	color: #fff;
	font-size: 87.97px;
	position: absolute;
	transform: translate(-50%, -25%);
}

.content h2:nth-child(1) {
    clip-path: polygon(57% 0, 100% 0%, 100% 100%, 57% 100%);
	color: white;
	/* -webkit-text-stroke: 2px #00AA5D; */
}

.content h2:nth-child(2) {
	color: #00AA5D;
	animation: animate 5s ease-in-out forwards;
}

@keyframes animate {
	0% {
        clip-path: polygon(0 64%, 10% 70%, 20% 70%, 29% 65%, 39% 64%, 50% 68%, 50% 100%, 0 100%);
	}
    10% {
        /* 1 */
        clip-path: polygon(0 62%, 9% 56%, 19% 57%, 29% 62%, 39% 61%, 50% 56%, 50% 100%, 0 100%);
    }
    20%{
        clip-path: polygon(0 64%, 10% 70%, 20% 70%, 29% 65%, 39% 64%, 50% 68%, 50% 100%, 0 100%);
    }
    30%{
        /* 2 */
        clip-path: polygon(0 47%, 8% 53%, 18% 53%, 29% 48%, 40% 49%, 50% 56%, 50% 100%, 0 100%);
    }
    40%{
        clip-path: polygon(0 62%, 9% 56%, 19% 57%, 29% 62%, 39% 61%, 50% 56%, 50% 100%, 0 100%);
    }
	50% {
        /* 3 */
		clip-path: polygon(0 46%, 8% 43%, 17% 42%, 28% 44%, 40% 43%, 50% 40%, 50% 100%, 0 100%);
	}
    60%{
        clip-path: polygon(0 47%, 8% 53%, 18% 53%, 29% 48%, 40% 49%, 50% 56%, 50% 100%, 0 100%);
    }
    70%{
        /* 4 */
        clip-path: polygon(0 29%, 9% 32%, 18% 32%, 29% 31%, 40% 29%, 50% 30%, 50% 100%, 0 100%);
    }
    80%{
        clip-path: polygon(0 46%, 8% 43%, 17% 42%, 28% 44%, 40% 43%, 50% 40%, 50% 100%, 0 100%);
    }
    90% {
        /* 5 */
        clip-path: polygon(0 24%, 8% 21%, 17% 21%, 28% 24%, 40% 25%, 50% 21%, 50% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 8% 0, 18% 0, 28% 0, 40% 0, 50% 0, 50% 100%, 0 100%);
	}
}
