.spinner {
    --gap: 10px;
    --clr: #00AA5D;
    --height: 28px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap);
   }
   
   .spinner span {
    background: var(--clr);
    width: 11px;
    height: var(--height);
    animation: grow 1s ease-in-out infinite;
   }
   
   .spinner span:nth-child(2) {
    animation: grow 1s ease-in-out 0.15s infinite;
   }
   
   .spinner span:nth-child(3) {
    animation: grow 1s ease-in-out 0.3s infinite;
   }
   
   .spinner span:nth-child(4) {
    animation: grow 1s ease-in-out 0.475s infinite;
   }
   
   @keyframes grow {
    0%,100% {
     transform: scaleY(1);
    }
   
    50% {
     transform: scaleY(1.8);
    }
   }