*{
  margin: 0px;
  padding: 0px;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: #151617 !important;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background:#00AA5D;
  border-radius: 10px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* header */
.headerAnimation{
  animation: show-header-left 0.5s forwards;
}
@keyframes show-header-left {
  0%{
    left:-100px;
  }
  100%{
    left:0px
  }
}
/* layout button style */
.buttonLayoutHoverText{
  opacity: 0;
  margin-top: 17.6px;
  transition: all 0.5s;
}
.buttonLayout{
  transition: all 0.4s;
  margin-bottom: 18.4px;
}
.buttonLayoutHoverIconShow{
  opacity: 0;
  transition: all 0.5s;
}
@media (min-width:660px) {
  .buttonLayout:hover .buttonLayoutHoverText{
    opacity: 1;
    transition: all 0.5s;
  }
  .buttonLayout:hover .buttonLayoutHoverIconShow{
    opacity: 1;
    transition: all 0.5s;
  }
  .buttonLayout:hover .buttonLayoutHoverIconHide{
    transition: all 0.4s;
    opacity: 0;
  } 
}
@media (max-width:659.5px) {
  .buttonLayout:active .buttonLayoutHoverText{
    opacity: 1;
    transition: all 0.5s;
  }
  .buttonLayout:active .buttonLayoutHoverIconShow{
    opacity: 1;
    transition: all 0.5s;
  }
  .buttonLayout:active .buttonLayoutHoverIconHide{
    transition: all 0.4s;
    opacity: 0;
  } 
}
.active .buttonLayoutHoverText{
  opacity: 0;
  transition: all 0.5s;
}
.active .buttonLayoutHoverIconHide{
  transition: all 0.4s;
  opacity: 1;
}
.active .buttonLayoutHoverIconShow{
  opacity: 1;
  transition: all 0.5s;
}
.logoHeight{
  margin-top: 39px;
  transition: all 0.4s;
}
.buttonsHeight{
  margin-top: 228px;
  transition: all 0.4s;
}
@media (max-height:899.5px) {
  .buttonsHeight{
    margin-top: 140px;
  }
  .logoHeight{
    margin-top: 20px;
  }
  .buttonLayoutHoverText{
    margin-top: 10px;
  }
  .buttonLayout{
    margin-bottom: 13.4px;
  }
}
@media (max-width:659.5px) {
  .buttonLayoutHoverText{
    margin-top: 8.13px;
  }
  .buttonLayout{
    margin-bottom: 9.75px;
  }
}
.animationSection1Title{
  position: relative;
  animation: show-title-section1 2s forwards;
}
@keyframes show-title-section1 {
  0%{
    opacity: 0;
    left:-100px
  }100%{
    opacity: 1;
    left: 0;
  }
}

.flip_body{
  position: relative;
  transition: all 0.8s ease 0s;
  transform-style: preserve-3d;
  animation: show-text-section1 5s infinite;
}
.flip_front, .flip_back{
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flip_front{
  z-index: 2;
  transform:rotateX(0deg);
  backface-visibility: hidden;
}
.flip_back{
  transform:rotateX(180deg);
  backface-visibility: hidden;
}
@keyframes show-text-section1 {
  0%{}
  50%{
    transform:rotateX(180deg);
  }
  100%{}
}



.flip_body_2{
  position: relative;
  transition: all 0.8s ease 0s;
  transform-style: preserve-3d;
  animation: show-text-section1_2 10s infinite;
}
.flip_front_2, .flip_back_2{
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flip_front_2{
  z-index: 2;
  transform:rotateX(0deg);
  backface-visibility: hidden;
}
.flip_back_2{
  transform:rotateX(180deg);
  backface-visibility: hidden;
}
@keyframes show-text-section1_2 {
  0%{
    transform:rotateX(0deg);
  }
  25%{
    transform:rotateX(180deg);
  }
  50%{
    transform:rotateX(0deg);
  }
  75%{
    transform:rotateX(180deg);
  }
  100%{
    transform:rotateX(0deg);
  }
}

.languageBtn3{
    animation: show-language-btn1-animation 1.5s forwards;
    transition: 0.4s;
}
.languageBtn2{
    animation: show-language-btn1-animation 1s forwards;
    transition: 0.4s;
}
.languageBtn1{
    animation: show-language-btn1-animation 0.5s forwards;
    transition: 0.4s;
}
@keyframes show-language-btn1-animation {
    0%{
        opacity: 0;
        margin-bottom: -62px;
    }
    100%{
        margin-bottom: 8px;
        opacity: 1;
    }
}

.languageBtn3Close{
    animation: show-language-btn1-animation-close 0.5s forwards;
    transition: 0.4s;
}
.languageBtn2Close{
    animation: show-language-btn1-animation-close 1s forwards;
    transition: 0.4s;
}
.languageBtn1Close{
    animation: show-language-btn1-animation-close 1.5s forwards;
    transition: 0.4s;
}
@keyframes show-language-btn1-animation-close {
    0%{
        margin-bottom: 8px;
        opacity: 1;
    }
    100%{
        opacity: 0;
        display: none;
        margin-bottom: -62px;
    }
}
.textLimitCards {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ant-progress-circle-path{
  stroke: white !important;
  stroke-width: 4px;
}
.ant-progress-inner svg{
  /* background: red !important; */
  /* border : 2px solid red !important; */
  /* color:red !important */
}
.ant-progress-text{
  color:white !important;
  font-size: 18px !important;
  line-height: 27px !important;
  font-weight: 600 !important;
}
.ant-progress-inner{
  width: 130px !important;
  height: 130px !important;
}
@media (max-width: 1199.5px) { 
  .ant-progress-text{
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 500 !important;
  }
  .ant-progress-inner{
    width: 100px !important;
    height: 100px !important;
  }
}
@media (max-width: 659.5px) { 
  .ant-progress-text{
    font-size: 18px !important;
    line-height: 27px !important;
    font-weight: 600 !important;
  }
  .ant-progress-inner{
    width: 130px !important;
    height: 130px !important;
  }
}
.lineAntProgress .ant-progress{
  display: flex;
  flex-direction: column-reverse;
}
.lineAntProgress .ant-progress-inner{
  width: 100% !important;
  height: auto !important;
  background-color: #151516 !important;
  border-radius: 5px !important;
}
.lineAntProgress .ant-progress-bg{
  border-radius: 5px !important;
}